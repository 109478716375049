import React, { Fragment, useContext, useEffect, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import { InfoButton, RadioActive, RadioInActive } from 'assets/img';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { checkIsDateAvailable, getOperatingHoursOptionsForDropdown } from 'utils/helpers/dateTime';
import GoogleAutoComplete from 'components/GoogleAutoComplete';
import InputError from 'components/InputError/InputError';
import moment from 'moment-timezone';
import { useRouter } from 'next/router';

const Error = ({ message = 'This field needs to be filled' }) => (
  <span className="text-attention text-xs mt-1 animate-fade-in-down">{message}</span>
);

function DeliveryPickupModal({
  visible,
  setModal,
  deliveryPickupModal,
  setAddItemModal,
  setAddressValidationAlert,
  setDateValidationAlert,
  restaurantDetail,
  openedItems,
}) {
  const screenType = Screen.ScreenType();
  const [isDelivery, setDelivery] = useState(true);
  const [isAdressValid, setIsValidAddress] = useState(true);
  const [internalAddress, setInternalAddress] = useState(null);
  const [addressError, setAddressError] = useState(false);
  const [comment, setComment] = useState('');
  const [completeAddressError, setCompleteAddressError] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const { address, completeAddress, prevValidAddress, prevValidCompleteAddress, addressTimezone } =
    userStore;

  const { cart } = cartStore;
  const router = useRouter();

  useEffect(() => {
    if (!store?.userStore?.isHydrated) return;

    setInternalAddress({ address, completeAddress });
  }, [address, store?.userStore?.isHydrated]);

  useEffect(() => {
    if (visible) {
      setDeliveryDate(cart?.cartDate);
      setDeliveryTime(cart?.cartTime);
    }
  }, [visible]);

  const closeModal = () => {
    setModal(false);
  };

  const apply = async () => {
    if (!internalAddress?.address) {
      setIsValidAddress(false);
      setAddressError(true);

      return;
    } else if (!internalAddress?.completeAddress?.city) {
      setCompleteAddressError(true);
      return;
    }

    store.userStore?.setAddress(internalAddress?.address, internalAddress?.completeAddress);

    if (!checkIsDateAvailable(restaurantDetail, deliveryDate, deliveryTime)) {
      closeModal();
      setTimeout(() => setDateValidationAlert(true), 500);
      return;
    }

    store.cartStore?.saveCartDetails({ comment, cartDate: deliveryDate, cartTime: deliveryTime });

    const params = {
      restaurant_id: restaurantDetail?.attributes?.restaurant_id,
      order_address: internalAddress?.address,
    };

    store.userStore.setLoader(true);
    let response = await store?.restaurantStore?.validateAddress(params);
    store.userStore.setLoader(false);

    if (!response?.valid) {
      const savedInternalAddress = internalAddress;
      store?.userStore?.setAddress(prevValidAddress, prevValidCompleteAddress);
      setAddressValidationAlert(true, savedInternalAddress);
      setIsValidAddress(false);
      return;
    } else {
      store?.userStore?.setPrevAddress(internalAddress?.address, internalAddress?.completeAddress);
      setIsValidAddress(true);
      closeModal();

      if (openedItems && !deliveryPickupModal?.fromCart)
        setTimeout(() => {
          setAddItemModal(true);

          router.replace(
            {
              pathname: router.pathname,
              query: {
                ...router.query,
                date: deliveryDate,
                time: deliveryTime,
              },
            },
            undefined,
            {
              shallow: true,
            }
          );
        }, 500);
    }
  };

  const isValidInfo = () => {
    return isAdressValid && cart?.cartDate && cart?.cartTime;
  };

  const getTimePickerOptions = () => {
    return getOperatingHoursOptionsForDropdown(
      restaurantDetail,
      deliveryDate ?? cart?.cartDate,
      addressTimezone
    );
  };

  const handleOnChange = e => {
    if (addressError) setAddressError(false);
    if (completeAddressError) setCompleteAddressError(false);

    setInternalAddress({ address: e?.target?.value });
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full bg-white md:bg-transparent md:items-center md:justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full h-full sm:h-auto md:w-5/12 transform overflow-visible sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 text-left align-middle md:shadow-xl transition-all">
                <div className="flex justify-between items-center">
                  <Dialog.Title
                    as="h3"
                    style={{ fontSize: screenType?.isTabletOrMobile ? 23 : 32 }}
                    className="font-inter-semibold leading-6 text-black-light">
                    {isDelivery ? ' Delivery Details' : 'Pick-Up Details'}
                  </Dialog.Title>

                  <button type="button" onClick={() => setModal(false)} className="btn-style">
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#BBC0C5"
                    />
                  </button>
                </div>

                <div className="flex flex-row items-center  bg-background rounded-md p-3 mt-5">
                  <div className="mr-3 mt-1">
                    <Image
                      src={InfoButton}
                      className="bg-background"
                      layout="fixed"
                      alt="info-button"
                      width={16}
                      height={16}
                    />
                  </div>
                  <span>
                    Earliest available delivery date for this restaurant is{' '}
                    {restaurantDetail?.attributes?.next_available_date ?? '-'}
                  </span>
                </div>

                {/* <div className="flex flex-col min-[560px]:flex-row flex-wrap mt-6">
                  <div
                    className="flex cursor-pointer"
                    onClick={() => setDelivery(true)}
                  >
                    <Image src={isDelivery ? RadioActive : RadioInActive} />

                    <span className="text-md ml-2">
                      I need for my order to be delivered
                    </span>
                  </div>

                  <div
                    className="flex mt-3 min-[560px]:mt-0 min-[560px]:ml-4 cursor-pointer"
                    onClick={() => setDelivery(false)}
                  >
                    <Image src={isDelivery ? RadioInActive : RadioActive} />

                    <span className="text-md ml-2">
                      I will pick up my order
                    </span>
                  </div>
                </div> */}

                <div>
                  <div className="flex flex-col mt-5">
                    <span className="text-md mb-1">Delivery address</span>
                    <div className="flex w-full md:mr-2 h-12">
                      <GoogleAutoComplete
                        className={classNames(
                          'input-light-1 outline-none focus:outline-none address-inputs font-inter-medium my-0 rounded-lg text-md w-full bg-background pl-4',
                          {
                            'border-attention border': addressError,
                          }
                        )}
                        onChange={handleOnChange}
                        value={internalAddress?.address}
                        onPlaceSelected={place => {
                          const _address = place?.address_components?.reduce(
                            (seed, { long_name, types }) => (
                              types.forEach(t => (seed[t] = long_name)), seed
                            ),
                            {}
                          );

                          store.userStore?.getAndSetTimezone(place?.formatted_address);

                          setInternalAddress({
                            address: place?.formatted_address,
                            completeAddress: {
                              suite: _address?.subpremise,
                              street_number: _address?.street_number,
                              street_name: _address?.route,
                              city: _address?.locality,
                              province: _address?.administrative_area_level_1,
                              postal_code: _address?.postal_code,
                            },
                          });
                        }}
                      />
                    </div>

                    {addressError && <Error />}

                    {completeAddressError && (
                      <InputError error="Please select address from the address dropdown" />
                    )}
                  </div>

                  <div className="flex flex-col md:flex-row justify-between mt-5 overflow-scroll md:overflow-visible">
                    <div className="flex flex-col w-full md:w-1/2">
                      <span className="text-md mb-1">Delivery date</span>

                      <DatePicker
                        isCart
                        isModal
                        isInternalSelection
                        activeRestaurant={restaurantDetail}
                        selectedDate={deliveryDate}
                        setSelectedDate={date => setDeliveryDate(moment(date).format('YYYY-MM-DD'))}
                        minDate={new Date()}
                        format={'MMMM d, yyyy'}
                        className="flex h-12 bg-background justify-between rounded-lg pr-2 items-center"
                      />
                    </div>

                    <div className="flex flex-col w-full overflow-visible md:w-1/2 mt-3 md:mt-0 md:ml-3">
                      <span className="text-md mb-1">Time</span>

                      <TimePicker
                        isCart
                        isInternalSelection
                        selectPicker={{
                          options: getTimePickerOptions(),
                        }}
                        timeZone={restaurantDetail?.attributes?.tzinfo}
                        setSelectedTime={time => setDeliveryTime(time)}
                        selectedDate={deliveryDate}
                        selectedTime={deliveryTime}
                        className="flex h-12 bg-background justify-between rounded-lg text-sm border-0 text-primary-black"
                      />
                    </div>

                    <div className="md:hidden pb-20 flex flex-col mt-5 pb-20 md:pb-0">
                      <span className="text-md mb-1">Comment</span>

                      <input
                        className="input-gray-bg text-sm"
                        placeholder="Ex. Leave at door"
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="hidden md:flex flex-col mt-5 pb-20 md:pb-0">
                    <span className="text-md mb-1">Comment</span>

                    <input
                      className="input-gray-bg text-sm"
                      placeholder="Ex. Leave at door"
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </div>
                </div>

                <div className="hidden md:flex justify-center items-center mt-16">
                  <div className="flex">
                    <button className="btn-light py-3 text-sm px-5" onClick={closeModal}>
                      Cancel
                    </button>

                    <button
                      className={classNames('btn-purple py-3 text-sm px-6 ml-3')}
                      onClick={apply}
                      // disabled={!isValidInfo()}
                    >
                      Apply
                    </button>
                  </div>
                </div>

                <div className="md:hidden">
                  <div className=" bg-white w-full py-5 pb-20 justify-center items-center mt-16 border-t-2 border-gray-200">
                    <div className="flex px-5 justify-evenly">
                      <button className="btn-light w-5/12 py-3 px-5" onClick={closeModal}>
                        Cancel
                      </button>

                      <button
                        className={classNames('btn-purple w-5/12 py-3 px-6 ml-3')}
                        // disabled={!isValidInfo()}
                        onClick={apply}>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(DeliveryPickupModal);
