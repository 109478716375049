import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { StoreContext } from 'pages/_app';
import { useContext } from 'react';

function StartGroupOrderBadge({ menuId, address }) {
  const router = useRouter();
  const store = useContext(StoreContext);

  const onClickStart = () => {
    if (!address) {
      store?.userStore?.setDeliveryPickupModal({
        state: true,
        fromCart: false,
      });

      return;
    } else {
      store.groupOrderStore.setCurrentGroupOrder(null);
      router.push({
        pathname: '/group-order/new',
        query: { ...router.query, menu_id: menuId },
      });
    }
  };

  return (
    <div className="flex flex-col w-full sm:flex-row p-6 sm:mt-4 bg-background rounded-xl sm:items-center sm:justify-between">
      <div className="flex flex-col w-full sm:w-[70%]">
        <div className="flex">
          <FontAwesomeIcon icon={faUserGroup} className="icon mr-2" />

          <span className="text-md font-inter-semibold">Group order</span>
        </div>

        <span className="text-sm mt-3">
          Easily share order with friends and colleagues so each person can add items
        </span>
      </div>

      <div>
        <button
          onClick={onClickStart}
          className="w-[86px] text-white btn-style py-[10px] px-6 text-md mt-4 bg-hh-accent-light hover:bg-hh-accent-medium rounded-[32px]">
          Start
        </button>
      </div>
    </div>
  );
}

export default observer(StartGroupOrderBadge);
